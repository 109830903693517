import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const reactStringReplace = require('react-string-replace')

function allergenTracesRenderer(traces) {
  if (traces) {
    return (
      <li>
        <strong>Allergener som det kan finnas spår av: </strong>
        {traces}
      </li>
    )
  }
}

function highlightAllergens(allergenStr) {
  return reactStringReplace(allergenStr, /([A-ZÅÄÖ]{2,})/g, (match, i) => (
    <strong key={i}>{match.toLowerCase()}</strong>
  ))
}

const Product = ({ product, breadcrumb }) => {
  const crumbs = breadcrumb.crumbs.slice(1)

  if (!product.image) {
    console.error(`Missing image for ${product.articleName}`)
  }

  return (
    <div className='featured-item'>
      <section className='section section--with-background'>
        <h1 className='page__heading'>{product.articleName}</h1>
        <Breadcrumb crumbs={crumbs} crumbLabel={product.articleName} />
      </section>
      <section className='section section--with-background product'>
        <div>
          <p>{product.description}</p>
          <ul className='product__list'>
            <li>
              <strong>Artikelnamn: </strong> {product.articleName}
            </li>
            <li>
              <strong>Artikelnummer: </strong> {product.articleNumber}
            </li>
            <li>
              <strong>Nettovikt: </strong> {product.netWeight}
            </li>
            <li>
              <strong>Antal i kartong: </strong> {product.piecesPerCarton}
            </li>
          </ul>
        </div>
        <GatsbyImage
          image={product.image}
          alt={product.articleName}
          imgStyle={{ objectFit: 'contain', objectPosition: 'top center' }}
        />
      </section>
      <section className='section section--with-background'>
        <h4 className='section__heading'>Konsumentinformation</h4>
        <p className='section__text'>
          Vi gör allt vi kan för att säkerställa att informationen om våra
          produkter är korrekt. Men eftersom ingredienserna ibland kan ändras
          bör du alltid läsa ingrediensförteckningen på förpackningen.
        </p>
        <ul className='list'>
          <li>
            <strong>Ingredienser: </strong>
            {highlightAllergens(product.ingredients)}
          </li>
          <li>
            <strong>Allergener i produkten: </strong> {product.allergens}
          </li>
          {allergenTracesRenderer(product.allergensTracesOf)}
        </ul>
      </section>
    </div>
  )
}

export default Product
